
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from './base/loggedInComponentBase';
import { getModule } from 'vuex-module-decorators';
import { CampaignModule } from '@/store';
import { CampaignDetail } from '@/models/campaigns/campaignDetail';
import { format } from 'date-fns';
import { AltDrawerType } from '@/constants';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

const campaignModule = getModule(CampaignModule);

@Component
export default class CampaignSidebar extends LoggedInComponentBase {
  @Prop() campaignCategoryId!: guid;

  public get drawerWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return '80%';
      case 'xl':
        return 360;
      default:
        return 300;
    }
  }

  getCampaignCategoryId(campaign: CampaignDetail) {
    if (this.campaignCategoryId) {
      return this.campaignCategoryId;
    } else if (campaign.categories.length > 0) {
      return campaign.categories[0].id;
    } else {
      return '';
    }
  }

  public get showCampaigns() {
    return Object.keys(this.upcomingCampaigns).length;
  }

  get upcomingCampaigns(): Array<CampaignDetail> {
    return campaignModule.upcomingCampaigns;
  }

  public closeButtonHandler() {
    this.uiModule.toggleAltDrawer({
      drawerType: AltDrawerType.CampaignSidebar,
      open: false,
    });
  }

  public drawerStateChangeHandler(state: boolean) {
    this.uiModule.toggleAltDrawer({
      drawerType: AltDrawerType.CampaignSidebar,
      open: state,
    });
  }

  formattedDuration(campaign: CampaignDetail): string {
    const startDate: Date = new Date(campaign.startDateTime);
    const endDate: Date = new Date(campaign.endDateTime);
    const campaignStartMonth: string = startDate.toLocaleString('default', {
      month: 'long',
    });
    const campaignEndMonth: string = endDate.toLocaleString('default', {
      month: 'long',
    });

    const st = format(startDate, 'do'),
      end = format(endDate, 'do');

    if (campaignEndMonth !== campaignStartMonth) {
      return `${st} ${campaignStartMonth} to ${end} ${campaignEndMonth}`;
    } else {
      if (st !== end) {
        return `${st} to ${end} ${campaignEndMonth}`;
      }
      // 1 day only campaign
      return `${st} ${campaignEndMonth}`;
    }
  }

  public getImageUrl(campaign: CampaignDetail) {
    return campaign.blobUrl
      ? cloudinaryImageResize(campaign.blobUrl, 120)
      : `${process.env.BASE_URL}img/brand/upcoming-campaign-sidebar-fallback.jpg`;
  }

  mounted() {
    campaignModule.getUpcomingCampaigns();
  }
}
