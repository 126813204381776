var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-navigation-drawer',{staticClass:"campaign-sidebar pt-md-13 px-3",attrs:{"value":_vm.campaignSidebarOpen,"app":"","fixed":"","right":"","width":_vm.drawerWidth},on:{"input":_vm.drawerStateChangeHandler}},[_c('v-toolbar',{staticClass:"d-md-none",attrs:{"flat":"","color":"#f1f1f1"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeButtonHandler}},[_c('v-icon',{staticClass:"fal fa-times black--text"})],1)],1),_c('div',{staticClass:"sidebar-header mb-8"},[_c('h3',{staticClass:"title font-weight-bold"},[_vm._v("Upcoming Campaigns")])]),(_vm.showCampaigns)?_vm._l((_vm.upcomingCampaigns),function(campaign,index){return _c('router-link',{key:campaign.id,staticClass:"text-decoration-none mx-5 my-3 d-block",class:index === 0 ? 'mt-0' : '',attrs:{"to":{
          name: 'creativecentre.campaignDetail',
          params: {
            category: _vm.getCampaignCategoryId(campaign),
            id: campaign.id,
          },
        }}},[_c('v-card',{attrs:{"width":"100%"}},[_c('div',{staticClass:"d-flex"},[_c('v-lazy',[_c('img',{staticClass:"campaign-img",attrs:{"height":"100","src":_vm.getImageUrl(campaign),"alt":""}})]),_c('div',{staticClass:"mx-2 my-2 campaign-content"},[_c('v-card-subtitle',{staticClass:"text-uppercase black--text pa-0 campaign-title",domProps:{"textContent":_vm._s(campaign.title)}}),_c('v-card-subtitle',{staticClass:"black--text caption pa-0 campaign-date",domProps:{"textContent":_vm._s(_vm.formattedDuration(campaign))}})],1)],1)])],1)}):[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',{staticClass:"black--text"},[_vm._v(" There are no campaigns to display at the moment. ")])],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }