
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component, Prop } from 'vue-property-decorator';
import { AssetListItem } from '@/models/assets/assetListItem';
import ViewAssetModal from '@/components/modals/viewAssetModal.vue';
import { SocialChannel } from '@/models/posts/socialChannels';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import sub from 'date-fns/sub';
import getAssetThumbnail from '@/helpers/get-asset-thumbnail';

@Component({ components: { ViewAssetModal } })
export default class AssetCard extends LoggedInComponentBase {
  @Prop() public asset!: AssetListItem;

  public get isMyAsset(): boolean {
    return !this.asset.isRecommended;
  }
  public get showShareButton(): boolean {
    return (
      this.isMyAsset &&
      Boolean(this.userModule.currentUser?.storeGroups?.length)
    );
  }
  public assetDialog: boolean = false;
  public showConfirmation: boolean = false;
  public showShareAssetModal: boolean = false;

  get assetChannels(): SocialChannel[] {
    return this.asset.availableChannels || [];
  }

  get lastUpdatedDate(): string {
    if (!this.asset.dateAdded) return '';

    const dayMonth = format(new Date(this.asset.dateAdded), 'dd MMM');
    const year = format(new Date(this.asset.dateAdded), 'yy');
    return `${dayMonth} '${year}`;
  }

  get isFreshAsset(): boolean {
    // Last updated with 24 hours
    if (!this.asset.dateAdded) return false;

    const lastUpdatedDate = new Date(this.asset.dateAdded);
    return isAfter(lastUpdatedDate, sub(new Date(), { hours: 24 }));
  }

  get categoryName(): string {
    return this.asset.campaignCategories
      ? this.asset.campaignCategories[0].title || ''
      : '';
  }

  get showAssetSummary(): boolean {
    return this.userModule.isMultiStoreOwner;
  }

  get assetType(): string {
    return this.asset.isVideoAsset ? 'Video Asset' : 'Asset';
  }

  get fullResolutionImageUrl() {
    return this.asset.blobUrl;
  }

  get imageUrl() {
    return getAssetThumbnail(this.asset);
  }

  public deleteAsset() {
    this.$emit('delete-asset', { assetId: this.asset.id });
  }

  public useAsset() {
    this.assetDialog = false;
    this.createModule.setSelectedAsset(this.asset);

    // check to see if user is in /create-asset flow
    if (this.$route.path.includes('/create-asset')) {
      this.$router.push({ path: '/create-asset' });
      return;
    }

    this.$router.push({ path: '/create-post' });
  }

  public openAssetDialog() {
    this.assetDialog = true;
  }

  public closeAssetDialog() {
    this.assetDialog = false;
  }
}
